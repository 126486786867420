<template>
  <div class="home">
    <!-- {{ glob.type }} -->
    <TopSwiperComponent />
    <FourBtnComponent :coinList="coinList" />
    <NewsCrawlComponent />
    <SecondSwiperComponent />
    <BtnNav v-if="domainType == 'mtf'" />
    <CoinListComponent :coinList="coinList" />
    <HomeInfoComponent />
    <WhitePaper />
    <FooterComponent />
  </div>
</template>

<script>
import TopSwiperComponent from "@/components/Home/TopSwiperComponent.vue";
import FourBtnComponent from "@/components/Home/FourBtnComponent.vue";
import NewsCrawlComponent from "@/components/Home/NewsCrawlComponent.vue";
import SecondSwiperComponent from "@/components/Home/SecondSwiperComponent.vue";
import CoinListComponent from "@/components/Home/CoinListComponent.vue";
import HomeInfoComponent from "@/components/Home/HomeInfoComponent.vue";
import WhitePaper from "@/components/Home/WhitePaper/WhitePaper.vue";
import FooterComponent from "@/components/Footer/FooterComponent.vue";
import BtnNav from "@/components/Home/BtnNav/BtnNav.vue";

import { getMarkets } from "@/services/Api.js";

export default {
  name: "HomeView",
  components: {
    TopSwiperComponent,
    FourBtnComponent,
    NewsCrawlComponent,
    SecondSwiperComponent,
    BtnNav,
    CoinListComponent,
    HomeInfoComponent,
    WhitePaper,
    FooterComponent,
  },
  data() {
    return {
      coinList: [],
      coinListNew: [],
      mtf: null,
      getDataInterval: null,
      ...this.glob
    };
  },
  mounted() {
    // console.log("glob.type", glob.type);
    this.getData();
    this.getDataInterval = setInterval(this.getData, 30000);
  },
  beforeDestroy() {
    clearInterval(this.getDataInterval);
  },
  methods: {
    getData() {
      this.coinListNew = [];
      getMarkets()
        .then((res) => {
          // console.log(res);
          const tempCoinList = res.data;

          tempCoinList.map((item, index) =>
            //   item.name === "MTF/USDT" || item.name === "IONE/USDT"
            //     ? (this.mtf = tempCoinList[index])
            //     : this.coinListNew.push(tempCoinList[index])
            this.coinListNew.push(tempCoinList[index])
          );

          // if (this.mtf) {
          //   this.coinListNew.unshift(this.mtf);
          // }
          this.coinList = this.coinListNew;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
