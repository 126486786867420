<template>
  <div class="drawer-btn-group">
    <div class="user_wrap">
      <el-avatar
        class="avatar"
        icon="el-icon-user-solid"
        v-if="login"
      ></el-avatar>

      <!-- <div class="user">{{ userData.email }}</div>

      <div class="amount_data">
        <div class="title">{{ $t("navbar.balance") }}:</div>
        <div class="title">{{ parseFloat(uadtData.balance || 0) }}</div>
      </div>

      <div class="amount_data">
        <div class="title">{{ $t("navbar.locked") }}:</div>
        <div class="title">{{ parseFloat(uadtData.locked || 0) }}</div>
      </div> -->

      <div class="drawer-btn" v-if="login">
        <img :src="require('@/assets/icons/User_light.svg')" alt="icon" />
        <span class="text">
          {{ userData.email }}
        </span>
      </div>
      <div class="drawer-btn" v-if="login">
        <img :src="require('@/assets/icons/Phone.svg')" alt="icon" />
        <span class="text"> {{ userData.phone_number || "-" }} </span>
      </div>
      <!-- <div class="drawer-btn" v-if="login">
        <img :src="require('@/assets/icons/Invite.svg')" alt="icon" />
        <div class="amount_data">
          <div class="title">{{ $t("navbar.inviteCode") }}:</div>
          <div class="title">{{ userData.code || "" }}</div>
        </div>
      </div> -->
      <!-- <div class="drawer-btn" v-if="login">
        <img :src="require('@/assets/icons/ID.svg')" alt="icon" />

        <div class="amount_data">
          <div class="title">{{ $t("navbar.idDocumentState") }}:</div>
          <div class="title">
            {{
              userData.id_document_state == "unverified"
                ? $t("navbar.unverified")
                : userData.id_document_state == "verifying"
                ? $t("navbar.verifying")
                : $t("navbar.verified")
            }}
          </div>
        </div>
      </div> -->
      <div class="drawer-btn" v-if="login">
        <img :src="require('@/assets/icons/USDT.svg')" alt="icon" />
        <div class="amount_data">
          <div class="title">{{ $t("navbar.balance") }}:</div>
          <div class="value">{{ parseFloat(uadtData.balance || 0) }}</div>
        </div>
      </div>
      <div class="drawer-btn" v-if="login">
        <img :src="require('@/assets/icons/Bet.svg')" alt="icon" />
        <div class="amount_data">
          <div class="title">{{ $t("navbar.locked") }}:</div>
          <div class="value">{{ parseFloat(uadtData.locked || 0) }}</div>
        </div>
      </div>

      <div class="btn_wrap" v-if="!login">
        <div class="btn" @click="jump('login')">
          {{ $t("navbar.login") }}
        </div>
        <div class="btn" @click="jump('register')">
          {{ $t("navbar.register") }}
        </div>
      </div>

      <el-divider class="divider"></el-divider>

      <div class="drawer-btn hover" @click="jump('wallet')" v-if="login">
        <img :src="require('@/assets/icons/Wallet.svg')" alt="icon" />
        <span class="text">
          {{ $t("navbar.wallet") }}
        </span>
      </div>
      <!-- <div class="drawer-btn hover" @click="jump('charge')" v-if="login">
        <img :src="require('@/assets/icons/in.svg')" alt="icon" />
        <span class="text">
          {{ $t("navbar.charge") }}
        </span>
      </div> -->
      <!-- <div class="drawer-btn hover" @click="jump('withdraw')" v-if="login">
        <img :src="require('@/assets/icons/out.svg')" alt="icon" />
        <span class="text">
          {{ $t("navbar.withDraw") }}
        </span>
      </div> -->
      <div class="drawer-btn hover" @click="jump('usercheck')" v-if="login">
        <img :src="require('@/assets/icons/ID.svg')" alt="icon" />
        <!-- <font-awesome-icon class="btn img" icon="fa-solid fa-id-card" /> -->
        <!-- <span class="text">
          {{ $t("navbar.usercheck") }}
        </span> -->

        <div class="amount_data hover">
          <div class="title">{{ $t("navbar.idDocumentState") }}:</div>
          <div class="value" :class="{ red: isReject }">
            {{ currentVerificationStatus() }}
          </div>
        </div>
      </div>
      <div
        class="drawer-btn hover"
        @click="jump('order')"
        v-if="login && domainType == 'mtf'"
      >
        <img :src="require('@/assets/icons/order.svg')" alt="icon" />
        <span class="text">
          {{ $t("navbar.orderCenter") }}
        </span>
      </div>
      <div class="drawer-btn hover" v-if="domainType == 'mtf'">
        <img :src="require('@/assets/icons/News.svg')" alt="icon" />
        <span class="text">
          {{ $t("navbar.newsCenter") }}
        </span>
      </div>
      <div
        class="drawer-btn hover"
        @click="showCustomerServiceFun"
        v-if="domainType == 'mtf'"
      >
        <img :src="require('@/assets/icons/chat.svg')" alt="icon" />

        <div>
          <span class="text">{{ $t("navbar.customerService") }}</span>
          <!-- <span class="blue">{{ "mtftoken@gmail.com" }}</span> -->
        </div>
      </div>
      <div class="drawer-btn hover">
        <img :src="require('@/assets/icons/News Copy.svg')" alt="icon" />

        <select
          class="hover"
          v-model="$i18n.locale"
          @change="handleClickLan($i18n.locale)"
        >
          <option
            v-for="({ value, name }, i) in langs"
            :key="`Lang${i}`"
            :value="value"
          >
            {{ name }}
          </option>
        </select>
      </div>
    </div>
    <!-- {{ $i18n.locale }} -->
    <span class="login_out hover" v-if="login" @click="loginoutFun">
      {{ $t("navbar.loginOut") }}
    </span>
  </div>
</template>

<script>
import Cookie from "js-cookie";
// import { getLogout } from "@/services/Api.js";

export default {
  inject: ["reload", "alertCsMsg", "message"],
  name: "DrawerComponent",

  props: {
    loginStatus: { Boolean },
    account: { String },
    userData: { Object },
    uadtData: { Object },
  },

  data() {
    return {
      route: this.$route.name,
      login: Cookie.get("sn") != null,
      langs: [
        {
          value: this.glob.domainType == "vcex" ? "ja" : "eng",
          name: this.glob.domainType == "vcex" ? "日本語" : "English",
        },
        {
          value: this.glob.domainType == "vcex" ? "eng" : "ja",
          name: this.glob.domainType == "vcex" ? "English" : "日本語",
        },
        {
          value: "zht",
          name: "繁體中文",
        },
        {
          value: "zhs",
          name: "简体中文",
        },
      ],
      isReject: false,
      ...this.glob
    };
  },

  watch: {
    $route(to) {
      this.route = to.name;
    },
  },

  methods: {
    handleClose() {
      this.$emit("handleClose");
    },

    jump(type) {
      this.handleClose();
      this.$router.push({ name: type }).catch(() => {});
    },

    loginoutFun() {
      // getLogout().then((res) => {
      //   console.log(res);
      // });

      this.message("navbar.logoutSuccess", "success");
      Cookie.remove("sn");
      Cookie.remove("_Toastio_session");
      this.$router.push("/").catch(() => {});
      this.reload();
    },

    /** 開啟新聞頁 */
    open() {
      window.open("https://mtftoken.com/news/zh-cn.html#last_news");
    },

    showCustomerServiceFun() {
      this.handleClose();
      this.alertCsMsg();
    },

    handleClickLan(lan) {
      this.$i18n.locale = lan;
      localStorage.setItem("locale", lan);
    },

    currentVerificationStatus() {
      switch (this.userData.id_document_state) {
        case "unverified":
          if (this.userData.id_document_reason != null) {
            this.isReject = true;
            return this.$t("navbar.reject");
          } else {
            this.isReject = false;
            return this.$t("navbar.unverified");
          }

        case "verifying":
          this.isReject = false;
          return this.$t("navbar.verifying");

        case "verified":
          this.isReject = false;
          return this.$t("navbar.verified");
      }
      return this.$t("navbar.unverified");
    },
  },
};
</script>

<style lang="scss" scoped>
.drawer-btn-group {
  width: 100%;
  height: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .avatar {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
  }

  .user {
    margin: 0.5rem 0;
    margin-bottom: 20px;
  }

  .amount_data {
    width: 100%;
    @include font_color("font_color3");
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    .red {
      @include font_color("font_color2");
    }
    .title {
      margin-right: 5px;
    }
  }

  .btn_wrap {
    display: flex;
    user-select: none;
    flex-direction: column;
    padding: 2rem 1.25rem;

    .btn {
      padding: 0.5rem 1rem;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      border-radius: 0.25rem;
      border-width: 1px;
      border: 1px solid;
      @include font_color("font_color3");
      @include border_color("border_color4");

      &:last-of-type {
        margin-top: 1rem;
        @include font_color("font_color4");
        @include background_color("background_color5");
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .login_out {
    font-size: 14px;
    width: fit-content;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    cursor: pointer;
  }

  .drawer-btn {
    width: 100%;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    font-weight: bold;
    margin: 8px 0;

    img {
      height: 30px;
      width: 30px;
      min-width: 30px;
    }

    span {
      padding-left: 16px;
      font-size: 14px;
      width: 100%;
      text-align: left;
      display: flex;
      align-items: center;
      height: 100%;
      &.blue {
        @include font_color("font_color5");
        line-height: 1;
      }
    }

    .text,
    select {
      font-size: 14px;
      @include background_color("background_color3");
      @include font_color("font_color3");
    }

    select {
      border: unset;
      width: 100%;
      outline: none;
      height: 30px;
      padding-left: 16px;
    }
  }
  .hover {
    cursor: pointer;

    &:hover {
      @include font_color("border_color4");
      .text {
        @include font_color("border_color4");
      }
    }
    option {
      @include font_color("font_color3");
    }
  }
  .selectedClass {
    @include font_color("border_color4");
  }

  .btn-box {
    width: 100%;
    position: absolute;
    bottom: 75px;
    @include flex-set();
    .logout-btn {
      width: 75%;
      height: 38px;
      @include background_color("background_color5");
      border-radius: 8px;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      cursor: pointer;

      &:hover {
        border: 1px solid;
        box-shadow: 0px 0px 10px 0px #2f83e180;
        @include background_color("background_color2");
        @include border_color("font_color5");
        @include font_color("border_color4");
      }
    }
  }
}

.divider {
  background-color: #666;
}

::v-deep .el-message {
  min-width: 200px !important;
}
</style>
