<template>
  <div class="footer_area">
    <div class="contact_box">
      <div class="title">
        {{ $t("footer.contact") }}:
        <span class="gray">
          <div class="small_title">{{ $t("footer.contactMail") }}:</div>
          <a
            class="link"
            :href="`mailto:${email}?subject=Feedback&body=Message`"
          >
            {{ email }}
          </a>
        </span>
        <span class="gray" v-if="telegram">
          <div class="small_title">Telegram:</div>
          <a
            style="color: #409eff; text-decoration: underline"
            :href="`https://t.me/${telegram}`"
            target="_blank"
            >@{{ telegram }}</a
          >
        </span>
        <span class="gray">
          <div class="small_title">
            {{ $t("navbar.OnlineCustomerService") }}:
          </div>
          <div class="link" @click="OpenLiveChat()">LiveChat</div>
        </span>
      </div>
    </div>


    <div class="footer_text">
      {{ copyright }}
    </div>
  </div>
</template>

<script>
export default {
  inject: ["OpenLiveChat"],
  name: "FooterComponent",
  data() {
    return {
      activeNames: 1,
      ...this.glob, // mtf/vcex
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.footer_area {
  width: 100%;
  background-image: url("~@/assets/images/Rectangle.png");
  background-size: 1920px 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: 150px;

  @include flex-set();
  .collapse-box {
    width: 95%;
  }
  .contact_box {
    width: 95%;

    .title {
      width: 100%;
      font-size: 14px;
      margin: 20px 0 10px;
      display: flex;
      flex-direction: column;

      span {
        margin-top: 8px;
      }
    }

    .gray {
      @include font_color("font_color6");
      width: 100%;
      @include flex-set();
      justify-content: left;
      margin: 0 auto;
      .small_title {
        width: 45%;
        text-align: right;
        margin-right: 5px;
      }
      .link {
        cursor: pointer;
      }
    }
  }
  .footer_text {
    font-size: 12px;
    margin: 10px 0 20px;
  }
}
</style>
