<template>
  <div class="btnNav">
    <div
      class="nav"
      v-for="({ img, val, link }, index) in list"
      :key="`nav${index}`"
      @click="handleClick(link)"
    >
      <img :src="img" alt="index" />
      <span>{{ $t(val) }}</span>
    </div>
  </div>
</template>

<script>
import Cookie from "js-cookie";

export default {
  inject: ["alertCsMsg", "message"],
  name: "BtnNav",

  data() {
    return {
      login: Cookie.get("sn") != null,
      list: [
        {
          img: require(`@/assets/icons/${this.glob.type}/Wallet.svg`),
          val: "home.wallet",
          link: "/wallet",
        },
        // {
        //   img: require(`@/assets/icons/ID.svg`),
        //   val: "home.usercheck",
        //   link: "/usercheck",
        // },
        {
          img: require(`@/assets/icons/${this.glob.type}/order.svg`),
          val: "home.orderlist",
          link: "/order",
        },
        {
          img: require(`@/assets/icons/${this.glob.type}/chat.svg`),
          val: "home.contactService",
        },
      ],
    };
  },

  methods: {
    handleClick(url) {
      switch (true) {
        case !url:
          this.alertCsMsg();
          break;
        case !this.login && url !== null:
          this.message("home.loginFirst", "warning");
          break;
        default:
          this.$router.push(url);
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btnNav {
  display: flex;
  justify-content: space-around;
  @include background_color("background_color4");
  border-radius: 15px;
  padding: 8px;
  width: calc(100% - 2rem);
  margin: 0 auto;
  text-align: center;

  .nav {
    display: flex;
    // flex-direction: column;
    max-width: 100px;
    cursor: pointer;

    img {
      // margin: 0 auto 2px;
      width: 36px;
      height: 36px;
      margin-right: 5px;
    }

    span {
      font-size: 16px;
      @include font_color("font_color10");
      height: 36px;
      line-height: 36px;
    }
  }
}
</style>
