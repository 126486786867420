import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VueCountryIntl from "vue-country-intl";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "vue-country-intl/lib/vue-country-intl.css";

import i18n from "./i18n/i18n";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

import MarqueeText from "vue-marquee-text-component";
import VueClipboard from "vue-clipboard2";

library.add(fab, fas, far);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.component("marquee-text", MarqueeText);

Vue.component(VueCountryIntl.name, VueCountryIntl);

Vue.use(ElementUI);
Vue.use(VueClipboard);

Vue.config.productionTip = false;

Vue.prototype.glob = {
  title: process.env.VUE_APP_TITLE,
  domainType: process.env.VUE_APP_DOMAIN_TYPE,
  domainName: "//" + process.env.VUE_APP_DOMAIN,
  telegram: process.env.VUE_APP_TELEGRAM,
  email: process.env.VUE_APP_EMAIL,
  logo: process.env.VUE_APP_LOGO || 'Logo.png',
  copyright: process.env.VUE_APP_COPYRIGHT,
  small_logo: process.env.VUE_APP_SMALL_LOGO || 'small_logo.png',
  type: "dark",
  liveChatLicense: "14335899",
};
document.title = Vue.prototype.glob.title;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
