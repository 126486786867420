<template>
  <div class="navbar">
    <div class="space">
      <div class="logo">
        <img
          :src="`/pak/${domainType}/${logo}`"
          class="logoimg"
          @click="jump('home')"
        />
      </div>
      <!-- <router-link class="logo" to="/" @click="handleClose">

      </router-link> -->
    </div>
    <div class="space">
      <div class="btngroup">
        <!-- <div class="darkModeBox" @click="darkModeFun">
          <font-awesome-icon
            class="btn darkMode darkModeSun"
            :class="[{ opacity_0: !darkMode }]"
            ref="darkModeSun"
            icon="fa-solid fa-sun"
          />
          <font-awesome-icon
            class="btn darkMode darkModeMoon"
            :class="[{ opacity_0: darkMode }]"
            ref="darkModeMoon"
            icon="fa-solid fa-moon"
          />
        </div> -->
        <font-awesome-icon
          class="btn"
          :icon="!this.drawerOpen ? 'fa-solid fa-bars' : 'fa-solid fa-close'"
          @click="openDrawerFun"
        />
      </div>
    </div>
    <!-- size:134px -->
    <el-drawer
      custom-class="drawer"
      :visible.sync="drawerOpen"
      :direction="direction"
      :before-close="handleClose"
      :show-close="false"
      :withHeader="false"
    >
      <DrawerComponent
        @handleClose="handleClose"
        :userData="userData"
        :uadtData="uadtData"
      />
    </el-drawer>

    <!-- <LiveChatWidget
      :license="glob.liveChatLicense"
      visibility="maximized"
      v-on:new-event="handleNewEvent"
    /> -->
  </div>
</template>

<script>
import DrawerComponent from "@/components/Navbar/DrawerComponent.vue";
import { getUser, getAccounts } from "@/services/Api.js";
import Cookie from "js-cookie";
// import { LiveChatWidget } from "@livechat/widget-vue/v2";

export default {
  inject: ["reload", "message"],
  name: "NavbarComponent",
  components: {
    DrawerComponent,
    // LiveChatWidget,
  },
  data() {
    console.log(this.glob)
    return {
      drawerOpen: false,
      direction: "rtl",
      uadtData: {},
      userData: {},
      darkMode: 0,
      darkModeRotate: 0,
      ...this.glob
    };
  },
  mounted() {
    if (Cookie.get("sn") != null) {
      this.getUserDataFun();
    }
  },
  methods: {
    handleClose() {
      this.drawerOpen = false;
    },
    openDrawerFun() {
      this.drawerOpen = !this.drawerOpen;
      if (this.drawerOpen && Cookie.get("sn") != null) {
        this.getUserDataFun();
        // if (Cookie.get("sn") != null) {
        // this.getAccountsFun();
        // }
      }
    },
    jump(type) {
      this.handleClose();
      this.$router.push({ name: type }).catch(() => {});
    },
    getUserDataFun() {
      getUser()
        .then((res) => {
          const { data, success } = res.data;
          if (success) {
            this.userData = data;
            this.$store.dispatch("setApiSettingsData", data);
            this.getAccountsFun();
          }
        })
        .catch((err) => {
          console.log(err);

          //登入驗證未通過
          if (
            err.response.status === 401
            // &&
            // err.response.statusText === "Unauthorized"
          ) {
            this.message("common.unauthorizedMsg", "error");
            Cookie.remove("sn");
            Cookie.remove("_Toastio_session");
            this.$router.push("/").catch(() => {});
            this.reload();
          }
        });
    },

    getAccountsFun() {
      getAccounts()
        .then((res) => {
          const { data } = res;
          if (data.success) {
            this.$store.dispatch("setApiAccountsData", data.data);
            data.data.map((item) =>
              item.code === "USDT" ? (this.uadtData = item) : null
            );
          }
        })
        .catch((err) => {
          console.log(err);

          //登入驗證未通過
          if (
            err.response.status == 401
            // &&
            // err.response.statusText == "Unauthorized"
          ) {
            this.message("common.unauthorizedMsg", "error");
            Cookie.remove("sn");
            Cookie.remove("_Toastio_session");
            this.$router.push("/").catch(() => {});
            this.reload();
          }
        });
    },
    darkModeFun() {
      this.darkMode = this.darkMode ? 0 : 1;

      localStorage.setItem("darkMode", this.darkMode ? 0 : 1);

      this.darkModeRotate += 180;
      this.$refs.darkModeSun.style.transform = `rotate(${this.darkModeRotate}deg)`;
      this.$refs.darkModeMoon.style.transform = `rotate(${this.darkModeRotate}deg)`;

      window.document.documentElement.setAttribute(
        "data-theme",
        this.darkMode ? "dark" : "light"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  height: 64px;
  @include background_color("background_color4");
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 30000;
  border-bottom: 1px solid;
  @include border_color("border_color1");
  .space {
    width: 33.3%;
    .returnimg {
      @include flex-set(wrap, unset, center);
      .returnImgBtn {
        width: 25px;
        height: 25px;
        margin-left: 11px;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .logo {
      display: flex;
      .logoimg {
        width: 148px;
        margin-left: 17px;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .btngroup {
      margin-right: 10px;
      display: flex;
      justify-content: flex-end;
      .btn {
        width: 20px;
        text-align: center;
        padding: 10px;
        @include font_color("font_color3");
        font-size: 20px;

        &:hover {
          cursor: pointer;
        }
      }
      .darkModeBox {
        position: relative;
        width: 40px;
        .darkMode {
          transition: all 500ms ease;
          position: absolute;
          left: 0;
          top: 0;
          opacity: 1;
        }
        .opacity_0 {
          opacity: 0;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.el-drawer__wrapper {
  height: calc(100% - 64px);
  margin-bottom: 0;
  top: 64px !important;
}
.el-drawer__container {
  overflow: hidden;
}
.el-drawer {
  @include background_color("background_color3");
  min-width: 220px !important;
  width: unset !important;
  padding: 16px;
}
</style>
