export default {
  mtf: {
    p1: "MTF Token is an artificial intelligence and future sharing technology. MTF-based virtual reality community and immersive interactive application ecosystem. It aims to build a virtual reality community in MTF to provide an open source for immersive interaction. interface, and digital twinning with offline entities, so as to achieve the goal of one household, one universe.",
    p2: "In the MTF community, it is possible to build an immersive interactive community space that comprehensively maps and reproduces the attributes of offline entities such as social community, entertainment, business, shopping, games, etc. By using the world's most advanced technology in the real world Create virtual. Various scenes in the real world are reproduced, such as cities, streets, real estate, commercial streets, etc. The visualization effect significantly improves the user experience, and people can experience immersive content of different scenes in this space.",
    p3: "MTF community wants to create the ultimate virtual reality hub that allows users to focus on an immersive and engaging environment. As such, this is the first time that users can access encrypted information and immersive content in one place, with access to different levels Blockchain educational resources. Additional content, virtual business resources, realistic gaming experiences, and anything else you can imagine with virtual reality experiences.",
    create: "Create",
    createStr:
      "The virtual character image in MTF can be customized by users, for example; wearing a virtual scarf purchased by oneself on a virtual person to generate a unique character with personalized matching, and these two secondary creation products have NFT anti-counterfeiting certificates. ",
    interact: "Interact",
    interactStr:
      "Rendering through VR and AR allows users to see more detailed real IP. Among them, AR supports virtual objects to be attached to the real environment, providing a virtual and real experience similar to a movie out of control player; virtual characters are projected into real life to complete the preview. Set actions and interact with elements in the real world.",
    share: "Share",
    shareStr:
      "Users can project their purchased virtual IPs into the real world through AR. Users can interact with avatars, post photos or videos of the content to the community, and also post their own secondary creations for sharing in the same way.",
    trade: "Trade",
    tradeStr:
      "Cooperative virtual IP holders, not limited to companies or individuals, can list and trade commodities on MTF. In the future, MTF will also create more SocialFi scenarios with immersive experiences, such as virtual land, virtual exhibitions, etc. Settled enterprises can sell virtual goods on virtual land, and in order to qualify for sales, companies need to lease land on virtual land.",
  },
  vcex: {
    p1: "IONE is an artificial intelligence and future sharing technology. IONE-based virtual reality community and immersive interactive application ecosystem. It aims to build a virtual reality community in IONE to provide an open source for immersive interaction. interface, and digital twinning with offline entities, so as to achieve the goal of one household, one universe.",
    p2: "In the IONE community, it is possible to build an immersive interactive community space that comprehensively maps and reproduces the attributes of offline entities such as social community, entertainment, business, shopping, games, etc. By using the world's most advanced technology in the real world Create virtual. Various scenes in the real world are reproduced, such as cities, streets, real estate, commercial streets, etc. The visualization effect significantly improves the user experience, and people can experience immersive content of different scenes in this space.",
    p3: "IONE community wants to create the ultimate virtual reality hub that allows users to focus on an immersive and engaging environment. As such, this is the first time that users can access encrypted information and immersive content in one place, with access to different levels Blockchain educational resources. Additional content, virtual business resources, realistic gaming experiences, and anything else you can imagine with virtual reality experiences.",
    create: "Create",
    createStr:
      "The virtual character image in IONE can be customized by users, for example; wearing a virtual scarf purchased by oneself on a virtual person to generate a unique character with personalized matching, and these two secondary creation products have NFT anti-counterfeiting certificates. ",
    interact: "Interact",
    interactStr:
      "Rendering through VR and AR allows users to see more detailed real IP. Among them, AR supports virtual objects to be attached to the real environment, providing a virtual and real experience similar to a movie out of control player; virtual characters are projected into real life to complete the preview. Set actions and interact with elements in the real world.",
    share: "Share",
    shareStr:
      "Users can project their purchased virtual IPs into the real world through AR. Users can interact with avatars, post photos or videos of the content to the community, and also post their own secondary creations for sharing in the same way.",
    trade: "Trade",
    tradeStr:
      "Cooperative virtual IP holders, not limited to companies or individuals, can list and trade commodities on IONE. In the future, IONE will also create more SocialFi scenarios with immersive experiences, such as virtual land, virtual exhibitions, etc. Settled enterprises can sell virtual goods on virtual land, and in order to qualify for sales, companies need to lease land on virtual land.",
  },
  wdc: {
    p1: "WDCC is an artificial intelligence and future sharing technology. WDCC-based virtual reality community and immersive interactive application ecosystem. It aims to build a virtual reality community in WDCC to provide an open source for immersive interaction. interface, and digital twinning with offline entities, so as to achieve the goal of one household, one universe.",
    p2: "In the WDCC community, it is possible to build an immersive interactive community space that comprehensively maps and reproduces the attributes of offline entities such as social community, entertainment, business, shopping, games, etc. By using the world's most advanced technology in the real world Create virtual. Various scenes in the real world are reproduced, such as cities, streets, real estate, commercial streets, etc. The visualization effect significantly improves the user experience, and people can experience immersive content of different scenes in this space.",
    p3: "WDCC community wants to create the ultimate virtual reality hub that allows users to focus on an immersive and engaging environment. As such, this is the first time that users can access encrypted information and immersive content in one place, with access to different levels Blockchain educational resources. Additional content, virtual business resources, realistic gaming experiences, and anything else you can imagine with virtual reality experiences.",
    create: "Create",
    createStr:
      "The virtual character image in WDCC can be customized by users, for example; wearing a virtual scarf purchased by oneself on a virtual person to generate a unique character with personalized matching, and these two secondary creation products have NFT anti-counterfeiting certificates. ",
    interact: "Interact",
    interactStr:
      "Rendering through VR and AR allows users to see more detailed real IP. Among them, AR supports virtual objects to be attached to the real environment, providing a virtual and real experience similar to a movie out of control player; virtual characters are projected into real life to complete the preview. Set actions and interact with elements in the real world.",
    share: "Share",
    shareStr:
      "Users can project their purchased virtual IPs into the real world through AR. Users can interact with avatars, post photos or videos of the content to the community, and also post their own secondary creations for sharing in the same way.",
    trade: "Trade",
    tradeStr:
      "Cooperative virtual IP holders, not limited to companies or individuals, can list and trade commodities on WDCC. In the future, WDCC will also create more SocialFi scenarios with immersive experiences, such as virtual land, virtual exhibitions, etc. Settled enterprises can sell virtual goods on virtual land, and in order to qualify for sales, companies need to lease land on virtual land.",
  },
  winn: {
    p1: "WDCC is an artificial intelligence and future sharing technology. WDCC-based virtual reality community and immersive interactive application ecosystem. It aims to build a virtual reality community in WDCC to provide an open source for immersive interaction. interface, and digital twinning with offline entities, so as to achieve the goal of one household, one universe.",
    p2: "In the WDCC community, it is possible to build an immersive interactive community space that comprehensively maps and reproduces the attributes of offline entities such as social community, entertainment, business, shopping, games, etc. By using the world's most advanced technology in the real world Create virtual. Various scenes in the real world are reproduced, such as cities, streets, real estate, commercial streets, etc. The visualization effect significantly improves the user experience, and people can experience immersive content of different scenes in this space.",
    p3: "WDCC community wants to create the ultimate virtual reality hub that allows users to focus on an immersive and engaging environment. As such, this is the first time that users can access encrypted information and immersive content in one place, with access to different levels Blockchain educational resources. Additional content, virtual business resources, realistic gaming experiences, and anything else you can imagine with virtual reality experiences.",
    create: "Create",
    createStr:
      "The virtual character image in WDCC can be customized by users, for example; wearing a virtual scarf purchased by oneself on a virtual person to generate a unique character with personalized matching, and these two secondary creation products have NFT anti-counterfeiting certificates. ",
    interact: "Interact",
    interactStr:
      "Rendering through VR and AR allows users to see more detailed real IP. Among them, AR supports virtual objects to be attached to the real environment, providing a virtual and real experience similar to a movie out of control player; virtual characters are projected into real life to complete the preview. Set actions and interact with elements in the real world.",
    share: "Share",
    shareStr:
      "Users can project their purchased virtual IPs into the real world through AR. Users can interact with avatars, post photos or videos of the content to the community, and also post their own secondary creations for sharing in the same way.",
    trade: "Trade",
    tradeStr:
      "Cooperative virtual IP holders, not limited to companies or individuals, can list and trade commodities on WDCC. In the future, WDCC will also create more SocialFi scenarios with immersive experiences, such as virtual land, virtual exhibitions, etc. Settled enterprises can sell virtual goods on virtual land, and in order to qualify for sales, companies need to lease land on virtual land.",
  },
};
