<template>
  <el-carousel
    class="TopSwiperComponent"
    height="50vw"
    indicator-position="none"
  >
    <el-carousel-item v-for="(item, index) in banner_data" :key="index">
      <img class="img" :src="item" />
    </el-carousel-item>
  </el-carousel>
</template>
<script>
export default {
  name: "TopSwiperComponent",
  props: {},
  components: {},
  data() {
    return this.glob;
  },
  computed: {
    banner_data() {
      return [
        require(`@/components/Home/banner/${this.$i18n.locale}/1.jpg`),
        require(`@/components/Home/banner/${this.$i18n.locale}/2.jpg`),
        require(`@/components/Home/banner/${this.$i18n.locale}/3.jpg`),
      ];
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.TopSwiperComponent {
  max-height: 350px;
  ::v-deep .el-carousel__container {
    max-height: 350px;
    overflow: hidden;
  }

  .el-carousel__item {
    @include background_color("background_color1");
  }

  .img {
    width: 100%;
  }
}
</style>
